<template>
  <v-container>
    <v-row class="justify-center">
      <v-card flat>
        <v-card-text>
          <v-data-table
            v-if="items"
            :headers="headers"
            :items="items"
            class="elevation-2"
          >
            <template v-slot:top>
              <v-toolbar dense flat class="teal" dark>
                <v-toolbar-title>Tipos de Cliente </v-toolbar-title>

                <v-spacer></v-spacer>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color=""
                      v-on="on"
                      v-bind="attrs"
                      class="mt-2 mb-2"
                      icon
                      x-large
                      to="/scheduling-status/adicionar"
                    >
                      <v-icon>mdi-plus-circle</v-icon>
                    </v-btn>
                  </template>
                  Cadastrar tipo de cliente
                </v-tooltip>
              </v-toolbar>
              <v-divider></v-divider>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-icon color="success" @click="edit(item)" class="mr-2"
                >mdi-square-edit-outline</v-icon
              >
              <v-icon @click="remove(item)" class="mr-2" color="error"
                >mdi-delete</v-icon
              >
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-row>
    <v-dialog v-model="editDialog" max-width="500px" v-if="editDialog">
      <editStatus
        :status="clienttype"
        v-on:update="getClientTypes()"
        v-on:close-dialog="editDialog = !editDialog"
      />
    </v-dialog>
    <v-dialog v-model="removeDialog" max-width="500px" v-if="removeDialog">
      <removetStatus
        :status="clienttype"
        v-on:update="getClientTypes()"
        v-on:close-dialog="removeDialog = !removeDialog"
      />
    </v-dialog>
  </v-container>
</template>
<script>
import cumbs from "@/mixins/cumbs";
import editStatus from "./edit";
import removetStatus from "./remove";

export default {
  mixins: [cumbs],
  components: {
    editStatus,
    removetStatus
  },
  data() {
    return {
      items: "",
      editDialog: false,
      removeDialog: false,
      clienttype: "",
      headers: [
        { text: "ID", value: "id" },
        { text: "Tipo", value: "type" },
        { text: "Ações", value: "actions" }
      ]
    };
  },
  created() {
    this.getClientTypes();
  },

  methods: {
    async getClientTypes() {
      const response = await this.$http.get("/client-types");
      this.items = response.data;
    },
    async edit(status) {
      this.editDialog = true;
      this.clienttype = status;
    },
    async remove(status) {
      this.removeDialog = true;
      this.clienttype = status;
    }
  }
};
</script>
<style></style>
