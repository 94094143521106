<template>
  <v-card>
    <v-toolbar dense flat class="teal" dark>
      <v-toolbar-title>Editar tipo de cliente</v-toolbar-title>

      <v-spacer></v-spacer>
    </v-toolbar>

    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="Nome do status"
              outlined
              v-model="typeclient.type"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-btn color="success" outlined @click="save()"> Salvar </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="error" outlined @click="closeDialog()"> Cancelar </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "editClient",
  props: ["status"],

  data() {
    return {
      typeclient: {
        id: this.status.id,
        type: this.status.type
      }
    };
  },

  methods: {
    async closeDialog() {
      this.$emit("close-dialog");
    },
    async save() {
      try {
        const response = await this.$http.put("/client-type", this.typeclient);
        if (response) this.$toast.success("editado com sucesso!");
        this.$emit("update");
        this.$emit("close-dialog");
      } catch (error) {
        this.$toast.error("Tente novamente mais tarde!");
        this.$emit("error");
      }
    }
  }
};
</script>

<style></style>
